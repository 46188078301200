import { HttpUtils } from '../../utils/HttpUtils';

const API_PATH = '//geoip.cdn.arkadiumhosted.com/json/';

interface GeoIpResponse {
    ip: string;
    country_code: string;
    country_name: string;
    region_code: string;
    region_name: string;
    city: string;
    zip_code: string;
    time_zone: string;
    latitude: number;
    longitude: number;
    metro_code: number;
}

export class GeoService {
    public static async getUserCountry(): Promise<string> {
        const headers = new Headers({ 'X-Geo-App': 'Arena5' });
        let data = await HttpUtils.fetch(API_PATH, { headers });

        return data.country_code
    }
}
